import React, { useEffect, useState } from "react"
import "./Edufooter.scss"
import { useHistory } from "react-router"
import Countdown from "react-countdown"
import api, {
  ASSETS_HOST_URL,
  GITHUB_ASSETS_HOST_URL,
} from "../../../../helpers/api"

const Edufooter = () => {
  const history = useHistory()
  const [nextClassDetail, setNextClassDetail]: any = useState([])

  useEffect(() => {
    callClassCourseApi()
  }, [])

  useEffect(() => {
    console.log(nextClassDetail, "=====nextClassDetail====nextClassDetail===")
  }, [nextClassDetail])

  const callClassCourseApi = async () => {
    try {
      const payload = await api.get("educator/liveclassinfo/")
      console.log(payload, "====payload?.data?.nextClass123")
      if (payload.status === 200) {
        if (payload?.data) {
          setNextClassDetail(
            JSON.parse(JSON.stringify(payload?.data?.nextClass))
          )
        }
      }
    } catch (e) {
      console.log("e", e)
    }
  }

  const openLiveClass = (data) => {
    if (data) {
      history.push(`/educator/live-class/${data?.classId}/${data?.sessionId}`)
    }
  }

  const CountdownRenderer = ({ total, days, hours, minutes, seconds }: any) => {
    if (total === 300000) {
      callClassCourseApi()
    }

    return (
      <>
        {total && total <= 300000 ? (
          <>
            <div className="Navbar_Button">
              <span className="Navbar_Button_Text timer">
                <div className="timer-box-design">{hours}</div>
                <div className="timer-box-design">{minutes}</div>
                <div className="timer-box-design">{seconds}</div>
              </span>
            </div>
            <div
              className={`Navbar_Button ${
                total >= 300000 ? "disabled blick_disable 2" : ""
              }`}
              data-tut="reactour__Go_Live"
            >
              <div className="Navbar_Button_Icon">
                <img
                  src={GITHUB_ASSETS_HOST_URL + `img/Edu_Dashboard/Go_Live.svg`}
                  alt="Logo"
                  className="Icon"
                />
              </div>
              <span
                className="Navbar_Button_Text"
                onClick={() => openLiveClass(nextClassDetail)}
              >
                <span> Go Live</span>
              </span>
            </div>
          </>
        ) : (
          <>
            {nextClassDetail && nextClassDetail.classId ? (
              <div
                className={`Navbar_Button ${
                  total >= 300000 ? "disabled blick_disable 3" : ""
                }`}
                data-tut="reactour__Go_Live"
              >
                <div className="Navbar_Button_Icon">
                  <img
                    src={
                      GITHUB_ASSETS_HOST_URL + `img/Edu_Dashboard/Go_Live.svg`
                    }
                    alt="Logo"
                    className="Icon"
                  />
                </div>
                <span
                  className="Navbar_Button_Text"
                  onClick={() => openLiveClass(nextClassDetail)}
                >
                  <span> Go Live</span>
                </span>
              </div>
            ) : null}
          </>
        )}
      </>
    )
  }

  return (
    <div className="edu_footer_container">
      <div className="edu_footer_row">
        <Countdown
          date={new Date(nextClassDetail?.date)}
          renderer={(data) => CountdownRenderer(data)}
        />
        {console.log(nextClassDetail, "====nextClassDetail?.date123===")}
        {nextClassDetail?.date ? null : (
          <div
            className={`Navbar_Button disabled blick_disable 1`}
            data-tut="reactour__Go_Live"
          >
            <div className="Navbar_Button_Icon">
              <img
                src={GITHUB_ASSETS_HOST_URL + `img/Edu_Dashboard/Go_Live.svg`}
                alt="Logo"
                className="Icon"
              />
            </div>
            <span
              className="Navbar_Button_Text"
              onClick={() => openLiveClass(nextClassDetail)}
            >
              <span> Go Live</span>
            </span>
          </div>
        )}

        <div className="Navbar_Single_Icon">
          <img
            src={
              GITHUB_ASSETS_HOST_URL + `img/Edu_Dashboard/Meeekoin-white.png`
            }
            alt="Meekoin"
            className="Icon"
            data-tut="reactour__Meekoins"
          />
        </div>

        <div
          className="Navbar_Single_Icon"
          onClick={() => history.push("/educator/help-desk")}
          data-tut="reactour__Help_Desk"
        >
          <img
            src={
              GITHUB_ASSETS_HOST_URL + `img/Edu_Dashboard/Question-white.png`
            }
            alt="Question"
            className="Icon"
          />
        </div>

        <div
          className="Navbar_Single_Icon"
          onClick={() => history.push(`/educator/chat`)}
        >
          <img
            src={GITHUB_ASSETS_HOST_URL + `img/Edu_Dashboard/chat-white.png`}
            alt="chat"
            className="Icon"
          />
        </div>
      </div>
    </div>
  )
}
export default React.memo(Edufooter)
