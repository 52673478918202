import { NavLink } from "react-router-dom"
import React, { useState, useEffect } from "react"
import "./EduSideBar.scss"
import { GITHUB_ASSETS_HOST_URL } from "../../../../helpers/api"

const EduSideBar = () => {
  const [scrollPosition, setScrollPosition] = useState(0)
  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <div
      className={`Edu_Dashboard_SideBar ${
        scrollPosition >= 40 ? "side-bar-up-scroll" : ""
      }`}
    >
      <div className="Edu_SideBar">
        <div className="Edu_Sidebar_Icon_Container">
          <NavLink
            className="Edu_UnACtive_Side"
            to="/educator/dashboard"
            activeClassName="active_nav_Side"
          >
            <div className="Edu_Icon_Container">
              <img
                src={GITHUB_ASSETS_HOST_URL + `img/Educator_Icon/dashboard.png`}
                alt="My_Classes"
              />
            </div>
          </NavLink>

          <NavLink
            className="Edu_UnACtive_Side"
            to="/educator/classes"
            activeClassName="active_nav_Side"
            data-tut="reactour__My_Classes"
          >
            <div className="Edu_Icon_Container">
              <img
                src={GITHUB_ASSETS_HOST_URL + `img/Educator_Icon/1 (6).png`}
                alt="My_Classes"
              />
            </div>
          </NavLink>

          <NavLink
            className="Edu_UnACtive_Side"
            to="/educator/calendar"
            activeClassName="active_nav_Side"
            data-tut="reactour__Calendar"
          >
            <div className="Edu_Icon_Container">
              <img
                src={GITHUB_ASSETS_HOST_URL + `img/Educator_Icon/1 (10).png`}
                alt="S1_Icon"
              />
            </div>
          </NavLink>
          <NavLink
            className="Edu_UnACtive_Side"
            to="/educator/my-space"
            activeClassName="active_nav_Side"
          >
            <div className="Edu_Icon_Container">
              <img
                src={GITHUB_ASSETS_HOST_URL + `img/Educator_Icon/1 (4).png`}
                alt="S1_Icon"
              />
            </div>
          </NavLink>
          <NavLink
            className="Edu_UnACtive_Side"
            to="/educator/test"
            activeClassName="active_nav_Side"
            data-tut="reactour__Test"
          >
            <div className="Edu_Icon_Container">
              <img
                src={GITHUB_ASSETS_HOST_URL + `img/Educator_Icon/1 (9).png`}
                alt="S1_Icon"
              />
            </div>
          </NavLink>
          <NavLink
            className="Edu_UnACtive_Side"
            to="/educator/finance"
            activeClassName="active_nav_Side"
          >
            <div className="Edu_Icon_Container">
              <img
                src={GITHUB_ASSETS_HOST_URL + `img/Educator_Icon/1 (8).png`}
                alt="S1_Icon"
              />
            </div>
          </NavLink>
          <NavLink
            className="Edu_UnACtive_Side"
            to="/educator/review-rating"
            activeClassName="active_nav_Side"
          >
            <div className="Edu_Icon_Container">
              <img
                src={GITHUB_ASSETS_HOST_URL + `img/Educator_Icon/1 (3).png`}
                alt="S1_Icon"
              />
            </div>
          </NavLink>
          <NavLink
            className="Edu_UnACtive_Side"
            to="/educator/video-requests"
            activeClassName="active_nav_Side"
          >
            <div className="Edu_Icon_Container">
              <img
                src={GITHUB_ASSETS_HOST_URL + `img/Educator_Icon/1 (5).png`}
                alt="S1_Icon"
              />
            </div>
          </NavLink>
          <NavLink
            className="Edu_UnACtive_Side"
            to="/educator/class-history"
            activeClassName="active_nav_Side"
            data-tut="reactour__Class_History"
          >
            <div className="Edu_Icon_Container">
              <img
                src={GITHUB_ASSETS_HOST_URL + `img/Educator_Icon/1 (2).png`}
                alt="S1_Icon"
              />
            </div>
          </NavLink>
          <NavLink
            className="Edu_UnACtive_Side"
            to="/educator/meeko-journey"
            activeClassName="active_nav_Side"
            data-tut="reactour__Meeko_Journey"
          >
            <div className="Edu_Icon_Container">
              <img
                src={GITHUB_ASSETS_HOST_URL + `img/Educator_Icon/1 (7).png`}
                alt="S1_Icon"
              />
            </div>
          </NavLink>
          <NavLink
            className="Edu_UnACtive_Side"
            to="/educator/reports"
            activeClassName="active_nav_Side"
          >
            <div className="Edu_Icon_Container">
              <img
                src={GITHUB_ASSETS_HOST_URL + `img/Educator_Icon/report.png`}
                alt="S1_Icon"
              />
            </div>
          </NavLink>
          <NavLink
            className="Edu_UnACtive_Side"
            to="/educator/growth"
            activeClassName="active_nav_Side"
          >
            <div className="Edu_Icon_Container">
              <img
                src={GITHUB_ASSETS_HOST_URL + `img/Educator_Icon/1 (11).png`}
                alt="S1_Icon"
              />
            </div>
          </NavLink>
          {/* <NavLink
            className="Edu_UnACtive_Side"
            to="/educator/refer"
            activeClassName="active_nav_Side"
          >
            <div className="Edu_Icon_Container">
              <img
                src={
                  require("../../../../assets/img/Educator_Icon/1 (1).png")
                    .default
                }
                alt="S1_Icon"
              />
            </div>
          </NavLink> */}
        </div>
        <div className="Edu_Sidebar_Text">
          <NavLink to="/educator/dashboard" activeClassName="active_side_menu">
            Dashboard
          </NavLink>

          <NavLink
            to="/educator/classes"
            activeClassName="active_side_menu"
            data-tut="reactour__My_Classes"
          >
            My Classes
          </NavLink>

          <NavLink
            to="/educator/calendar"
            activeClassName="active_side_menu"
            data-tut="reactour__Calendar"
          >
            Calendar
          </NavLink>
          <NavLink to="/educator/my-space" activeClassName="active_side_menu">
            My Space
          </NavLink>
          <NavLink
            to="/educator/test"
            activeClassName="active_side_menu"
            data-tut="reactour__Test"
          >
            Test
          </NavLink>
          <NavLink to="/educator/finance" activeClassName="active_side_menu">
            Finance
          </NavLink>
          <NavLink
            to="/educator/review-rating"
            activeClassName="active_side_menu"
          >
            Reviews & Rating
          </NavLink>

          <NavLink
            to="/educator/video-requests"
            activeClassName="active_side_menu"
          >
            Video Requests
          </NavLink>

          <NavLink
            to="/educator/class-history"
            activeClassName="active_side_menu"
            data-tut="reactour__Class_History"
          >
            Class History
          </NavLink>

          <NavLink
            to="/educator/meeko-journey"
            activeClassName="active_side_menu"
            data-tut="reactour__Meeko_Journey"
          >
            Meeko Journey
          </NavLink>

          <NavLink to="/educator/reports" activeClassName="active_side_menu">
            Reports
          </NavLink>
          <NavLink to="/educator/growth" activeClassName="active_side_menu">
            Growth
          </NavLink>
          {/* <NavLink
            to="/educator/refer"
            activeClassName="active_side_menu"
            style={{ border: "none" }}
          >
            Refer
          </NavLink> */}
        </div>
      </div>
      
    </div>
  )
}

export default React.memo(EduSideBar)
