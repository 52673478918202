import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { connect } from 'react-redux';
// import Header from '../components/layout/header/header';
// import LiveClassHeader from '../components/layout/liveClassHeader/liveClassHeader';
import '../index.scss';
import EducatorLayout from './EducatorLayout';

interface MyRouteProps extends RouteProps {
    component: any;
    authenticated: boolean;
    liveClasslayout?: boolean;
    rest?: any;
    componentProps?: object;
    withLayout?: boolean;
}

// const EducatorPrivateRoute: React.FC<MyRouteProps> = ({ component: Component, authenticated, role, liveClasslayout, ...rest }: any) => (
//     <Route {...rest} render={(props) =>
//     (authenticated && role === "Educator" ?
//         <EducatorLayout {...props} >
//             <Component {...props} liveClasslayout={liveClasslayout} />
//         </EducatorLayout>
//         : <Redirect to='/' />)} />
// );

const EducatorPrivateRoute: React.FC<MyRouteProps> = ({ component: Component, authenticated, role, withLayout = true, liveClasslayout, componentProps, ...rest }: any) => {
    return <Route {...rest} render={(props) =>
        (authenticated && role === "Educator" ? <>
      
      {
        withLayout ? <EducatorLayout {...props} >
        <Component {...props} { ...componentProps } liveClasslayout={liveClasslayout} />
      </EducatorLayout> : <Component {...props} { ...componentProps } liveClasslayout={liveClasslayout} />
      }
    </> : <Redirect to='/' />)
    } />
  };

const mapStateToProps = (state: any) => ({
    authenticated: state.auth.authenticated,
    role: state.auth.role
});

export default connect(mapStateToProps)(EducatorPrivateRoute);
