import { Suspense, Fragment, useState, useEffect } from "react"
import api, { ENDPOINT, decryptAPIRes, socket } from "../../helpers/api"
import axios, { AxiosRequestConfig } from "axios"
import { useHistory } from "react-router"
import Tour from "reactour"
import Loader from "../../components/common/SiteLoader/Loader"
import EduHeader from "../../components/educator/Components/Header/EduHeader"
import EduSideBar from "../../components/educator/Components/SideBar/EduSideBar"
import Edufooter from "../../components/educator/Components/EduFooter/Edufooter"
import { setChatDetailEmpty } from "../../store/chat/action"
import useWindowSize from "../../components/dashboard/useWindowSize"
import React from "react"

const EducatorLayout = ({ children }) => {
  const { width, height }: any = useWindowSize()
  const [isTourOpen, setIsTourOpen]: any = useState(
    localStorage.getItem("websiteTour")
  )
  const history = useHistory()

  useEffect(() => {
    if (history?.location?.pathname != "/educator/chat") {
      setChatDetailEmpty()
      socket.emit("closechatpanel", localStorage.getItem("token"))
    }
  }, [history])

  useEffect(() => {
    setIsTourOpen(localStorage.getItem("websiteTour"))
  }, [localStorage.getItem("websiteTour")])

  useEffect(() => {
    const token: any = localStorage.getItem("token")
    const role: any = localStorage.getItem("role")
    if (
      history.location.pathname !== "/educator/education-profile" &&
      history.location.pathname !== "/educator/help-desk"
    ) {
      if (localStorage.token && localStorage.role) {
        try {
          const config: AxiosRequestConfig = {
            method: "get",
            url: ENDPOINT + "/auth/check",
            headers: {
              "x-authorization": `${token}`,
            },
          }
          axios(config)
            .then(function (response) {
              var payload = decryptAPIRes(response.data.data)
              if (payload.code === 200) {
              } else {
                if (role == "ELearner") {
                  history.push("/expired-package")
                } else if (role == "Parent") {
                  history.push("/parent/expired-package")
                } else {
                  history.push("/educator/expired-package")
                }
              }
            })
            .catch(function (error) {
              var data = decryptAPIRes(error)
              console.log("error", data)
            })
        } catch (err) {
          console.log("err", err)
        }
      }
    }
  }, [])

  const closeTour = async () => {
    try {
      if (isTourOpen) {
        const payload = await api.get("/educator/websitetour")
        if (payload.status === 200) {
          localStorage.setItem("websiteTour", "false")
        }
        setIsTourOpen(false)
      }
    } catch (err) {
      console.log("error", err)
    }
  }

  const tourConfig = [
    {
      selector: '[data-tut="reactour__letsstart"]',
      content: "Site tour, let's start",
    },
    {
      selector: '[data-tut="reactour__edu_graph"]',
      content: "See how you are doing from various angles",
    },
    {
      selector: '[data-tut="reactour__edu_nextclass"]',
      content: "See your next upcoming class here",
    },
    {
      selector: '[data-tut="reactour__Class_Progress"]',
      content: "See all your classes, batches, sessions progress here",
    },
    {
      selector: '[data-tut="reactour__My_Classes"]',
      content: "Manage and create all your classes here",
    },
    {
      selector: '[data-tut="reactour__Calendar"]',
      content: "See your schedule of all the classes here",
    },
    {
      selector: '[data-tut="reactour__Test"]',
      content: "Create and manage tests here",
    },
    {
      selector: '[data-tut="reactour__Class_History"]',
      content: "See all your class history & attendance here",
    },
    {
      selector: '[data-tut="reactour__Meeko_Journey"]',
      content: "See your badge and journey here",
    },
    {
      selector: '[data-tut="reactour__Go_Live"]',
      content: "Enter in your Pre-made classes with just one button here",
    },
    {
      selector: '[data-tut="reactour__Meekoins"]',
      content: "See and manage your Meekoins, Meeko’s currency here",
    },
    {
      selector: '[data-tut="reactour__Profile"]',
      content: "See and edit your profile here",
    },
    {
      selector: '[data-tut="reactour__Help_Desk"]',
      content: "If you have any queries or questions go to Help Desk",
    },
  ]

  return (
    <Fragment>
      <Suspense fallback={<Loader />}>
        {width > 1025 && (
          <Tour
            onRequestClose={closeTour}
            steps={tourConfig}
            isOpen={isTourOpen == "true" ? true : false}
            maskClassName="mask"
            className="helper"
            rounded={5}
            accentColor="#5cb7b7"
            disableDotsNavigation={true}
            disableInteraction={true}
            lastStepNextButton={
              <button className="completeTour" onClick={() => closeTour()}>
                Done! Let's start teaching
              </button>
            }
          />
        )}
        {children.props.liveClasslayout ? (
          <div
            className="Dashboard_Main_Container"
            style={{
              height: height + "px",
              overflow: "auto",
            }}
          >
            <EduHeader />
            <div
              className="Dashboard_Content_Container"
              style={{
                height: height - 73 + "px",
              }}
            >
              <div className="Edu_Dashboard_Sidebar">
                <EduSideBar />
              </div>
              {children}
            </div>

            <Edufooter />
          </div>
        ) : (
          <>{children}</>
        )}
      </Suspense>
    </Fragment>
  )
}

export default React.memo(EducatorLayout)
